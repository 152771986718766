document.addEventListener("DOMContentLoaded", function () {
  const slideshows = document.querySelectorAll(".slideshow-fade");

  slideshows.forEach(slideshow => {
    const images = Array.from(slideshow.querySelectorAll("img"));
    let currentIndex = 0;

    if (images.length < 2) return;

    // Initialize images
    images.forEach((img, index) => {
      // img.style.position = "absolute"; // Ensures stacking behavior
      // img.style.top = "0";
      // img.style.left = "0";
      // img.style.width = "100%"; // Ensures proper alignment
      // img.style.height = "100%";
      img.style.opacity = index === 0 ? "1" : "0";
      img.style.zIndex = index === 0 ? "2" : "1"; // First image on top
      img.style.display = "block";
    });

    function getRandomInterval() {
      const min = 4000, max = 8000;
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function fadeToNext() {
      const nextIndex = (currentIndex + 1) % images.length;

      // Ensure the next image is prepared
      images[nextIndex].style.display = "block";
      images[nextIndex].style.opacity = "1";
      images[nextIndex].style.zIndex = "1"; // Temporarily place below

      // Move current image on top before fading out
      images[currentIndex].style.zIndex = "2";
      images[currentIndex].style.transition = "opacity 1s ease-in-out";
      images[currentIndex].style.opacity = "0"; 

      setTimeout(() => {
        // Once faded, hide the current image and adjust z-index
        images[currentIndex].style.display = "none";
        images[currentIndex].style.transition = "none";
        images[currentIndex].style.zIndex = "1"; // Reset for next cycle
        images[nextIndex].style.zIndex = "2"; // Next image takes priority
        currentIndex = nextIndex;
      }, 1000); // Wait for fade-out to complete

      setTimeout(fadeToNext, getRandomInterval());
    }

    setTimeout(fadeToNext, getRandomInterval());
  });
});
